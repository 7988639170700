import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { ContentfulImageQuery, Img, WmkImage } from "wmk-image";
import { WmkLink } from "wmk-link";
import { colors } from "../../vars/palette";
import { Type } from "./Typography";
import { FiDownload } from "react-icons/fi";
import { ButtonLink } from "../../classes/ButtonLink";
import {
  AiFillCaretDown,
  AiOutlineInfoCircle,
  AiOutlineShareAlt
} from "react-icons/ai";
import ReactPlayer from "react-player";

interface MediaAssetImage extends ContentfulImageQuery {
  filename: string;
  size: number;
}

const InfoDropdown = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  .information-name {
    p {
      margin-bottom: 0;
      padding-top: 2px;
    }
  }
  .information-icon {
    font-size: 20px !important;
  }
  .chevron {
    font-size: 26px;
  }
`;

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  padding: 0rem !important;
  height: 230px;
  h3 {
    text-align: left;
    margin: 1rem 0 0.5rem;
  }
`;

const MediaAssetContainer = styled(Container)<{ info: boolean }>`
  transition: all 0.3s ease;
  .file-detail-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .button-col {
    padding-right: 0;
    padding-left: 0;
  }
  .info-col {
    border: solid black 2px;
  }
  .row {
    padding-bottom: 0.5rem;
  }
  .info-card {
    opacity: ${({ open }) => (open ? 1 : 0)};
    z-index: ${({ open }) => (open ? 1 : -1)};
    position: ${({ open }) => (open ? "relative" : "absolute")};
  }
  .link-copied-message {
    display: inline;
    margin: 0 0.5rem 0 1rem;
    p {
      margin: 0 0.5rem;
    }
  }
`;

const ButtonContainer = styled.div<{
  invisible?: boolean;
  color?: string;
  textColor?: string;
  rounded?: boolean;
  fluid?: boolean;
  fontSize?: string;
  bold?: boolean;
  hoverColor?: string;
  innerPadding?: string;
  height?: string;
  style?: CSS.Properties;
}>`
  max-width: 100%;
  width: 100%;
  align-items: center;
  border-bottom: ${(props) =>
    props.invisible ? `6px solid transparent` : "none"};
  background-color: ${colors.blue.hex};
  border-radius: ${(props) => (props.rounded ? "10px" : "unset")};
  box-sizing: border-box;
  color: ${colors.white.hex};
  cursor: pointer;
  display: ${(props) => (props.fluid ? "flex" : "inline-flex")};
  font-size: "14px";
  font-weight: ${(props) => (props.bold ? 700 : 400)};

  &:hover {
    border-bottom: ${(props) =>
      props.invisible ? `6px solid ${colors.green.hex}` : "none"};
    color: ${(props) =>
      props.invisible ? colors.black.hex : colors.white.hex};
  }

  &:active {
    background-color: ${colors.blue.hex};
    border-bottom: ${(props) =>
      props.invisible ? `6px solid ${colors.green.hex}` : "none"};
    color: ${(props) =>
      props.invisible ? colors.black.hex : colors.white.hex};
  }

  & > div:first-child {
    width: 100%;
  }

  a {
    align-items: center;
    color: unset;
    display: ${(props) => (props.fluid ? "block" : "contents")};
    display: flex;
    height: 100%;
    justify-content: center;
    padding: ${(props) =>
      props.innerPadding ? props.innerPadding : "0.25rem 2rem"};
    text-decoration: none;
    width: 100%;
    &:hover {
      text-decoration: none;
    }
    p {
      color: ${colors.white.hex};
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
    }
    h5 {
      color: ${colors.white.hex};
    }
  }
`;

const StyledInfoButton = styled.button<{ open: boolean }>`
  display: inline-block;
  color: ${({ chevron }) => (chevron ? colors.text.hex : colors.text.hex)};
  &:hover {
    color: ${colors.black.hex};
  }
  border: none;
  background: none;
  display: inline-block;
  width: auto !important;
  & > div {
    transition: all 0.3s ease;
    transform: ${({ open }) =>
      open ? "scale(1) rotate(180deg)" : "scale(1) rotate(0)"};
  }
  position: relative;
  z-index: 2;
`;

const MediaAssetBlock = ({
  title,
  webImage,
  printImage,
  videoTitle,
  videoDescription,
  videoUrl,
  currentLink,
  setCurrentLink
}: {
  title: string;
  webImage?: MediaAssetImage;
  printImage?: MediaAssetImage;
  videoTitle?: string;
  videoDescription?: string;
  videoUrl?: string;
  currentLink?: string;
  setCurrentLink?: Function;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [videoWidth, setVideoWidth] = useState(0);
  const [videoDisplay, setVideoDisplay] = useState(false);
  const [videoEmbedUrl, setVideoEmbedUrl] = useState("");
  const videoRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    const current = videoRef.current;
    const handleResize = () => {
      const rect = current ? current.getBoundingClientRect() : null;
      if (rect) {
        setVideoWidth(rect.width);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (videoUrl) {
      setTimeout(() => {
        setVideoDisplay(true);
      }, 1000);
    }
  }, []);

  useEffect(() => {
    setVideoEmbedUrl("https://youtube.com/embed/" + getVideoId(videoUrl));
  }, []);

  const handleDropdown = () => {
    if (isOpen === true) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const handleSetLink = (title: string) => {
    setCurrentLink(title);
  };

  const webImageImg = new Img(webImage);
  const printImageImg = new Img(printImage);
  const isVideo = videoDescription && videoTitle && videoUrl ? true : false;

  const imageDownload = (source: string, name: string) => {
    var url = source;
    var fileName = name;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement("a");
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  };

  const displayAsset = (image: Img, text: string, title: string) => {
    return (
      <ButtonContainer>
        <WmkLink
          to={"#"}
          style={{ position: "relative" }}
          onClick={() => imageDownload(image.src, title)}>
          <Type.Body
            style={{
              fontSize: "2.25rem",
              position: "absolute",
              left: "1rem",
              color: colors.white.hex,
              marginBottom: "0.5rem"
            }}>
            <FiDownload />
          </Type.Body>
          <Type.Body>{text}</Type.Body>
        </WmkLink>
      </ButtonContainer>
    );
  };

  const getInfo = () => {
    const sizeOf = (bytes: number) => {
      if (bytes == 0) {
        return "0.00 B";
      }
      var e = Math.floor(Math.log(bytes) / Math.log(1024));
      return (
        (bytes / Math.pow(1024, e)).toFixed(2) + " " + " KMGTP".charAt(e) + "B"
      );
    };
    return (
      <>
        <InfoDropdown onClick={() => handleDropdown(open)}>
          <div style={{ display: "inline-block" }} className="information-icon">
            <AiOutlineInfoCircle />
          </div>
          <div style={{ display: "inline-block" }} className="information-name">
            <Type.Body>INFORMATION</Type.Body>
          </div>
          <StyledInfoButton open={isOpen} chevron>
            <div
              style={{ display: "flex", alignItems: "center" }}
              className="chevron">
              <AiFillCaretDown />
            </div>
          </StyledInfoButton>
        </InfoDropdown>
        <Container className="info-card">
          <Row>
            <Col>
              {webImage && (
                <>
                  <Type.Body className="file-detail-text">
                    Web File Name: {webImage.filename}
                  </Type.Body>
                  <Type.Body className="file-detail-text">
                    Web File Size:{" "}
                    {typeof webImage.size === "number"
                      ? sizeOf(webImage.size)
                      : "error"}
                  </Type.Body>
                </>
              )}
              {printImage && (
                <>
                  <Type.Body className="file-detail-text">
                    Print File Name: {printImage.filename}
                  </Type.Body>
                  <Type.Body className="file-detail-text">
                    Print File Size:{" "}
                    {typeof printImage.size === "number"
                      ? sizeOf(printImage.size)
                      : "error"}
                  </Type.Body>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  let updatedUrl;
  if (videoUrl) {
    updatedUrl = videoUrl.replace("watch?v=", "embed/");
  }

  const getVideo = () => {
    // console.log('"https://www.youtube.com/embed/" + getVideoId(videoUrl)', "https://www.youtube.com/embed/" + getVideoId(videoUrl))
    return (
      <iframe
        width="100%"
        height={videoWidth * 0.5625}
        src={videoEmbedUrl}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        // referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen></iframe>
    );
  };

  function getVideoId(videoUrl) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = videoUrl?.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  const videoId = getVideoId(videoUrl);
  const iframeMarkup =
    '<iframe src="https://www.youtube.com/embed/' +
    videoId +
    '" frameborder="0" allowfullscreen></iframe>';

  // console.log('Video ID:', videoId) // fB4Ca1iTii8
  // console.log("https://www.youtube.com/embed/" + videoId)

  return (
    <>
      <MediaAssetContainer fluid style={{ margin: "2vh 0" }} open={isOpen}>
        {isVideo ? (
          <>
            <Row>
              <Col ref={videoRef}>
                {videoUrl && updatedUrl.includes("youtube") ? (
                  <>
                    <iframe
                      width="100%"
                      height={videoWidth * 0.5625}
                      src={videoEmbedUrl}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      // referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen></iframe>
                    {/* <iframe
                      width="100%"
                      height={videoWidth * 0.5625}
                      src={updatedUrl}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen></iframe> */}
                  </>
                ) : (
                  <></>
                  // <ReactPlayer
                  //   url={"https://www.youtube.com/embed/" + getVideoId(videoUrl)}
                  //   width={"100%"}
                  //   height={videoWidth * 0.5625}
                  //   controls
                  //   config={{
                  //     youtube: {
                  //       playerVars: { "data-cmp-ab": "2" }
                  //     }
                  //   }}
                  //   data-cmp-ab="2"
                  // />
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Type.Body>{videoTitle}</Type.Body>
                <Type.Body style={{ fontSize: "14px" }}>
                  {videoDescription}
                </Type.Body>
              </Col>
            </Row>
            <Row>
              <Col>
                <div
                  onClick={() => {
                    handleSetLink(videoTitle);
                    navigator.clipboard.writeText(videoUrl);
                  }}>
                  <div style={{ fontSize: "34px", display: "inline-block" }}>
                    <AiOutlineShareAlt />
                  </div>
                  <Type.Body
                    style={{ display: "inline-block" }}>{`Share`}</Type.Body>
                  {currentLink === videoTitle ? (
                    <div className="link-copied-message">
                      <Type.Body
                        style={{
                          display: "inline-block",
                          color: colors.white.hex,
                          background: colors.green.hex
                        }}>{`URL COPIED!`}</Type.Body>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <ButtonContainer>
                  <WmkLink to={videoUrl}>
                    <Type.Body>{`WATCH VIDEO`}</Type.Body>
                  </WmkLink>
                </ButtonContainer>
              </Col>
            </Row>
            {videoUrl === "https://www.youtube.com/watch?v=cT8gOhvsVmU" ? (
              <Row>
                <Col>
                  <WmkLink to={"https://www.youtube.com/@ductileironpipes"}>
                    <Type.Body>{`Visit the YouTube Page`}</Type.Body>
                  </WmkLink>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <Row>
              <StyledCol>
                <WmkImage image={webImage ? webImageImg : printImageImg} />
              </StyledCol>
            </Row>
            <Row>
              <Col className="info-col">{getInfo()}</Col>
            </Row>
            {webImage && (
              <Row>
                <Col className="button-col">
                  {webImage &&
                    displayAsset(webImageImg, "WEB", webImage.filename)}
                </Col>
              </Row>
            )}
            {printImage && (
              <Row>
                <Col className="button-col">
                  {printImage &&
                    displayAsset(printImageImg, "PRINT", printImage.filename)}
                </Col>
              </Row>
            )}
          </>
        )}
      </MediaAssetContainer>
    </>
  );
};
export default MediaAssetBlock;
